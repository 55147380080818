import React from 'react'
import SliderSection from '../sesction/SliderSection'
import IntroSection from '../sesction/IntroSection'
// import GallerySection from '../sesction/GallerySection'
import LetestBlogSection from '../sesction/LetestBlogSection'
import TestimonialSection from '../sesction/TestimonialSection'
import PartnnersSlide from '../components/partner/PartnnersSlide'
import PlacementSlide from '../components/partner/PlacementSlide'
import SocialPostSection from '../sesction/SocialPostSectopn'

export default function HomePage() {
    return (
        <>
            <SliderSection />
            <IntroSection />
            {/* <GallerySection /> */}
            {/* <DonationSection /> */}
            <LetestBlogSection />
            <TestimonialSection/>
            
            <PartnnersSlide />
            <PlacementSlide />
            <SocialPostSection/>

        </>
    )
}
