import React from 'react'
import { Link } from 'react-router-dom'

const data = [
    {
        url: "http://blog.seswhrs.org",
        title: "Blog / Artical",
        img: "blog-artical.png"
    }, {
        url: "/donation",
        title: "Give Donation",
        img: "donate.png"
    }, {
        url: "/volunteer",
        title: "Become a Volunteer",
        img: "become-volunteer.png"
    }, {
        url: "/contact",
        title: "Help & support",
        img: "help.png"
    },
]

export default function IntroSection() {
    return (
        <section className="section intro">
            <div className="container">
                {/* <div className="columns is-align-items-center is-desktop mb-6">
                    <div className="column is-6-desktop">
                        <div className="section-title mb-0">
                            <span className="text-color">What we can do</span>
                            <h2 className="mt-4 content-title">
                                We Believe that We can <br />
                                Save More Lifes with you
                            </h2>
                        </div>
                    </div>
                    <div className="column is-6-desktop">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt, dicta,
                            iure. Esse quasi labore aperiam, dolorem amet voluptas soluta
                            asperiores nostrum voluptate molestias numquam similique. Voluptate
                            natus corporis ex, distinctio.
                        </p>
                    </div>
                </div> */}
                <div className="columns is-multiline is-mobile is-justify-content-center">
                    {
                        data.map((item, i) => (
                            <div className="column is-3-desktop is-6-tablet is-6-mobile " key={`blg-${i}`}>
                                <Link to={item.url} target={i===0?"_blank":"_self"} className="intro-item mb-5 mb-lg-0">
                                    <img src={`/assets/images/${item.img}`} alt={item.title} className="w-100" />
                                    <h4 className="mt-4 mb-3">{item.title}</h4>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>

    )
}
