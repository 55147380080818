import React from 'react'
import { Link } from 'react-router-dom'

export default function FooterComponent({ data }) {
    return (
        <footer className="footer pt-5">
            <div className="container pt-5">
                <div className="columns is-multiline">
                    <div className="column is-5-desktop is-6-tablet">
                        <div className="footer-widget widget">
                            <h4 className="is-capitalize mb-4 text-white">Registered Office Address</h4>
                            <p>{data.address}</p>
                            <ul className="list-unstyled footer-menu mt-4">
                                <li>
                                    <Link to={`tel:${data.phone1}`}>
                                        <i className="icofont-phone" />
                                        {data.phone1}
                                    </Link>
                                </li><li>
                                    <Link to={`tel:${data.contact}`}>
                                        <i className="icofont-phone" />
                                        {data.contact}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`mail:${data.email}`}>
                                        <i className="icofont-email" />
                                        {data.email}
                                    </Link>
                                </li>
                            </ul>
                            <ul className="list-inline footer-socials">
                                {
                                    data?.social && data.social.map((v, i) =>
                                        <li key={`social_${i}`} className="list-inline-item">
                                            <Link to={v.link}>
                                                <i className={`icofont-${v.icon}`} />
                                            </Link>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="column is-4-desktop is-6-tablet">
                        <div className="footer-widget widget">
                            <h4 className="is-capitalize mb-4 text-white">Support Office Address</h4>
                            <p>{data.assressSupport}</p>
                            <ul className="list-unstyled footer-menu mt-4">
                                <li>
                                    <Link to={`tel:${data.phone2}`}>
                                        <i className="icofont-phone" />
                                        {data.phone2}
                                    </Link>
                                    <Link to={`tel:${data.phone3}`}>
                                        <i className="icofont-phone" />
                                        {data.phone3}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`mail:${data.supportemail}`}>
                                        <i className="icofont-email" />
                                        {data.supportemail}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="column is-3-desktop is-6-tablet">
                        <div className="widget footer-widget">
                            <h4 className="is-capitalize mb-4 text-white">Quick Links</h4>
                            <ul className="list-unstyled footer-menu lh-35">
                                <li>
                                    <Link to="/about">About</Link>
                                </li>
                                <li>
                                    <Link to="/donation">Donate Now</Link>
                                </li>
                                <li>
                                    <Link to="/volunteer">Volunteer</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* <div className="column is-3-desktop is-6-tablet">
                        <div className="widget footer-widget">
                            <h4 className="is-capitalize mb-4 text-white">Gallery</h4>
                            <div className="gallery-wrap">
                                <div className="gallery-img">
                                    <img src="/assets/images/blog/gallery-1.jpg" alt="" className="" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="footer-btm">
                    <div className="columns is-justify-content-center">
                        <div className="column is-12">
                            <div className="copyright has-text-centered">
                                <small>© Copyright <Link  className="text-color" to="#">SESWHRS</Link></small>
                                <span className="text-right"> | Design by  <Link
                                    to="https://technowebtech.com/"
                                    target="_blank"
                                >Technowebtech</Link></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
