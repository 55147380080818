import React from 'react'

export default function SocialPostSection() {
    return (
        <>
            <section className="section  pb-0">
                <div className="container">
                    <div className="columns is-justify-content-center">
                        <div className="column is-8-desktop is-12-tablet">
                            <div className="content has-text-centered">
                                <h2 className=" mb-5">Social Post</h2>
                            </div>
                        </div>
                    </div>
                    <div className="columns is-multiline is-justify-content-center">
                        <div className="column is-6-desktop is-6-tablet"  style={{ marginBottom: "3em" }}>
                            <div
                                className="fb-page"
                                data-href="https://www.facebook.com/SESWHRSDOON/"
                                data-tabs="timeline"
                                data-width="500"
                                data-height=""
                                data-small-header="true"
                                data-adapt-container-width="true"
                                data-hide-cover="false"
                                data-show-facepile="true"
                            >
                                <blockquote
                                    cite="https://www.facebook.com/SESWHRSDOON/"
                                    className="fb-xfbml-parse-ignore"
                                >
                                    <a href="https://www.facebook.com/SESWHRSDOON/">
                                        Shivalik Educational Social Welfare and Human Resource Society
                                    </a>
                                </blockquote>
                            </div>
                        </div>
                        <div className="column is-6-desktop is-6-tablet twitter-card" style={{ height: "53vh", overflowY: "scroll", marginBottom: "3em" }}>
                            <div class='sk-ww-twitter-feed' data-embed-id='25483117'></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
